import React, { useState } from 'react';

import { QBadge } from '../../Q-Components/QBadge';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QLink } from '../../Q-Components/QLink';
import { QTypography } from '../../Q-Components/QTypography';
import { QButton } from '../../Q-Components/QButton';

export const AppMetadata = ({
  appUrl,
  iconUrl,
  name,
  pckg,
  platform,
  version,
  submissionTag,
  otherAttributes = [],
}) => {
  const [viewMore, setViewMore] = useState(false);

  const viewType = {
    true: 'Less',
    false: 'More',
  };

  const platformIcon = {
    ios: 'fab fa-apple',
    android: 'fab fa-android',
  };

  return (
    <QRow columnGap={5}>
      <QCol xs="1.5">
        <QLink href={appUrl} target={appUrl}>
          <img
            alt="logo"
            src={iconUrl}
            height="150px"
            width="150px"
            style={{ borderRadius: '13.75px' }}
          />
        </QLink>
      </QCol>
      <QCol>
        <h4>
          <QTypography variant="h4" component="h4">
            <strong>{name}</strong>
          </QTypography>
        </h4>
        <h6>
          <QTypography variant="h6" component="h6">
            {pckg}
          </QTypography>
        </h6>
        <h6>
          <QTypography variant="h6" component="h6">
            <i className={platformIcon[platform]} /> {version}
          </QTypography>
        </h6>

        {otherAttributes.map(({ label, value, defaultView }) => {
          if (value && (viewMore || defaultView)) {
            return (
              <h6 key={label}>
                <QTypography variant="h6" component="h6">
                  {label}: {value}
                </QTypography>
              </h6>
            );
          }
          return null;
        })}

        {submissionTag && <QBadge label={submissionTag} variant="basic" />}

        <QButton
          color="link"
          variant="ghost"
          onClick={() => setViewMore(!viewMore)}
        >
          View {viewType[viewMore]}
        </QButton>
      </QCol>
    </QRow>
  );
};
