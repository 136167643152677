import React from 'react';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { getCWEs, getRuleName, getSeverity } from '../../../logic/dopplerUtil';
import { RiskBadge } from '../../RiskBadge';

export function DopplerNextDetailsModal({
  isOpen,
  toggle,
  selectedItem,
  dopplerResults,
}) {
  console.log('Selected item:', selectedItem);

  if (!selectedItem) {
    return null;
  }
  const sourceLocation = _.get(
    selectedItem,
    'codeFlows[0].threadFlows[0].locations[0].location.physicalLocation',
    'N/A',
  );
  let sinkLocation = 'N/A';
  try {
    sinkLocation = selectedItem.codeFlows[0]?.threadFlows
      .slice(-1)[0]
      .locations.slice(-1)[0].location.physicalLocation;
  } catch (err) {
    console.log('Error getting sink location');
  }

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <>
        <ModalHeader>Code Path Analysis Item Details</ModalHeader>
        <ModalBody>
          <p className="bold">Type</p>
          <p>
            <RiskBadge risk={getSeverity(selectedItem.properties.score)} />{' '}
            {getRuleName(dopplerResults, selectedItem.ruleId)}
          </p>
          <hr />

          <p>{selectedItem.message.text}</p>
          <hr />

          {/* CWEs */}
          <p>
            <strong>CWEs</strong>
          </p>
          <ul>
            {getCWEs(dopplerResults, selectedItem.ruleId).map((cwe, index) => (
              <li key={index}>
                <a
                  // extract CWE ID from cwe string and then appends it to the URL for CWE definition page
                  href={`https://cwe.mitre.org/data/definitions/${
                    cwe.match(/\d+/)[0]
                  }.html`}
                  target="_blank"
                >
                  {cwe}
                </a>
              </li>
            ))}
          </ul>
          <hr />

          <h4>Source</h4>
          <div className="dont-break-out">
            <i>{sourceLocation.artifactLocation.uri}</i>
            <p>
              <code>{sourceLocation.region.snippet.text}</code>
            </p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <i className="fal fa-arrow-down fa-3x" />
          </div>

          <h4>Sink</h4>
          <div className="dont-break-out">
            <i>{sinkLocation.artifactLocation.uri}</i>
            <p>
              <code>{sinkLocation.region.snippet.text}</code>
            </p>
          </div>
        </ModalBody>
      </>
    </Modal>
  );
}
